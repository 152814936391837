<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :Threshold="searchForm" class="demo-form-inline">
        <!-- <el-form-item label="设备类型">
          <el-input clearable size="small" v-model="searchForm.deviceCodeName" placeholder="请输入设备类型" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="设备型号">
          <el-input clearable size="small" v-model="searchForm.deviceType" placeholder="请输入设备型号" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="厂家名称">
          <el-input clearable size="small" v-model="searchForm.manufactorName" placeholder="请输入厂家名称" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item> -->
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增阈值</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="thresholdList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="title" label="标题" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="modelName" label="设备型号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="jcqTypeName" label="模拟量类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="higher" label="上限" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="lower" label="下限" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteThreshold(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle v-role="'deviceThreshold:del'"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 设备型号弹窗 -->
    <el-dialog :title="optFlag?'新增设备阈值':'修改设备阈值'" :visible.sync="thresholdShow" width="30%" @close="clear" top="5%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="thresholdForm" ref="thresholdForm" :rules="rules" label-width="100px">
        <el-form-item label="模拟量类型" prop="jcqTypeId">
          <el-select v-model="thresholdForm.jcqTypeId" placeholder="请选择模拟量类型" clearable style="float:left">
            <el-option v-for="(item,i) in jcqTypeList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="modelId">
          <el-select v-model="thresholdForm.modelId" placeholder="请选择设备型号" clearable filterable remote :remote-method="remoteMethod3" style="float:left">
            <el-option v-for="(item,i) in modelOptions" :key="i" :label="item.deviceType" :value="item.id">
              <span style="float: left">{{ item.deviceType }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.deviceCodeName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上限" prop="higher">
          <el-input v-model="thresholdForm.higher" placeholder="请输入上限" size="normal" style="float:left" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="下限" prop="lower">
          <el-input v-model="thresholdForm.lower" placeholder="请输入下限" size="normal" style="float:left" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="thresholdForm.title" placeholder="请输入标题" size="normal" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="thresholdShow = false">取 消</el-button>
        <el-button type="primary" @click="addThreshold" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateThreshold" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      thresholdList: [],
      deviceThresholdId: 0, // 设备型号ID
      thresholdForm: {}, // 建筑表单
      searchForm: {},
      modelOptions: [],
      jcqTypeList: [],
      thresholdShow: false,
      loading: false,
      optFlag: true,
      rules: {
        jcqTypeId: [
          { required: true, message: '请选择模拟量类型', trigger: 'change' }
        ],
        modelId: [
          { required: true, message: '请选择设备型号', trigger: 'change' }
        ],
        higher: [
          { required: true, message: '请输入上限', trigger: 'blur' }
        ],
        lower: [
          { required: true, message: '请输入下限', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.getJcqTypeList()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化设备型号表格
    async initTable () {
      const data = {}
      // if (this.searchForm.deviceCodeName) {
      //   data.deviceCodeName = this.searchForm.deviceCodeName
      // }
      // if (this.searchForm.deviceType) {
      //   data.deviceType = this.searchForm.deviceType
      // }
      // if (this.searchForm.manufactorName) {
      //   data.manufactorName = this.searchForm.manufactorName
      // }
      data.pageNo = this.currentPage
      data.pageSize = 10

      this.loading = true

      const { data: result } = await this.$axios.get('/equipmentJcqLimit/list', { params: data })
      if (result.code === 200) {
        this.loading = false
        this.thresholdList = result.data.result
        this.total = result.data.total
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteThreshold (id) {
      const confirm = await this.$confirm('此操作将永久删除该设备型号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/equipmentJcqLimit/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    updateThreshold: _.debounce(function () {
      this.$refs.thresholdForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/equipmentJcqLimit/edit/${this.deviceThresholdId}`, this.thresholdForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.thresholdShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    // 新增建筑
    addThreshold: _.debounce(function () {
      this.$refs.thresholdForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/equipmentJcqLimit/add', this.thresholdForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.thresholdShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.thresholdShow = true
          this.$nextTick(() => {
            this.$refs.thresholdForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.thresholdShow = true
          this.optFlag = false
          this.$nextTick(() => {
            this.$refs.thresholdForm.clearValidate()
          })
          this.thresholdForm = JSON.parse(JSON.stringify(row))
          this.deviceThresholdId = row.id
          break
      }
    },
    // 获取模拟量类型
    async getJcqTypeList () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'jcqType' } })
      if (result.code === 200) {
        this.jcqTypeList = result.data
      }
    },
    // 查找设备型号
    async remoteMethod3 (query) {
      const { data: result } = await this.$axios.get('/equipmentModel/list', { params: { deviceType: query } })
      if (result.code === 200) {
        this.modelOptions = result.data
      }
    },
    clear () {
      this.thresholdForm = {}
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
